import { Alert, AlertIcon, AlertTitle, AlertDescription } from '@chakra-ui/react';
import {
	CartBar,
	FooterThreeColumn,
	HeroImageBackground,
	HeroImageLeft,
	HeroImageRight,
	MapTrail,
	NavigationBar,
	PageHeaderCenteredOnAccent,
	PerksThreeColumn,
	RandomProductSlider,
	TierBenefits,
} from '@ebbo/pulse-uikit';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const keysToComponentMap: Record<string, any> = {
	CartBar: CartBar,
	FooterThreeColumn: FooterThreeColumn,
	HeroImageBackground: HeroImageBackground,
	HeroImageLeft: HeroImageLeft,
	HeroImageRight: HeroImageRight,
	MapTrail: MapTrail,
	NavigationBar: NavigationBar,
	PageHeaderCenteredOnAccent: PageHeaderCenteredOnAccent,
	PerksThreeColumn: PerksThreeColumn,
	RandomProductSlider: RandomProductSlider,
	TierBenefits: TierBenefits,
	//Basic HTML Elements
	div: 'div',
};

interface ITemplateType {
	resolvedName: string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	props?: any;
	nodes?: string[];
}

type JSONTemplate = Record<
	string,
	| {
			type: string | ITemplateType;
			isCanvas?: boolean;
			props?: object;
			displayName?: string;
			custom?: object;
			hidden?: boolean;
			nodes?: string[];
			linkedNodes?: object;
			parent?: string;
	  }
	| undefined
>;

interface IRenderEngineProps {
	layout: JSONTemplate;
	isRoot: boolean;
	childKey?: string;
}

export const RenderEngine: React.FC<IRenderEngineProps> = ({ layout, childKey, isRoot }) => {
	// console.log('RenderEngine::', layout);
	if (isRoot) {
		// console.log('Is Root!');
		if (!layout.ROOT) {
			return (
				<Alert status="error">
					<AlertIcon />
					<AlertTitle>Invalid Template Detected!</AlertTitle>
					<AlertDescription>Unable to load site at this time.</AlertDescription>
				</Alert>
			);
		} else {
			let elem = '';
			if (typeof layout.ROOT.type === 'string') {
				elem = layout.ROOT.type;
			} else {
				elem = layout.ROOT.type.resolvedName;
			}
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			const eleProps = layout.ROOT.props;
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			const Tag = keysToComponentMap[elem];

			// console.log(elem);
			// console.log(eleProps);
			// console.log(Tag);

			return (
				<Tag {...eleProps}>
					{layout.ROOT.nodes?.map((v: string, i: number) => {
						return (
							<RenderEngine
								layout={layout}
								childKey={v}
								key={`child_${v}_${i.toString()}`}
								isRoot={false}
							/>
						);
					})}
				</Tag>
			);
		}
	} else {
		if (childKey && layout[childKey]) {
			// console.log(layout[childKey]);
			let elem = '';
			if (typeof layout[childKey].type === 'string') {
				elem = layout[childKey].type;
			} else {
				elem = layout[childKey].type.resolvedName;
			}
			// const elem = layout[childKey].type.resolvedName as string;
			const eleProps = layout[childKey].props;

			// if (elem === 'string') {
			// 	return eleProps.value;
			// }

			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			const Tag = keysToComponentMap[elem];

			return (
				<Tag {...eleProps}>
					{layout[childKey].nodes?.map((v: string, i: number) => {
						return (
							<RenderEngine
								isRoot={false}
								layout={layout}
								childKey={v}
								key={`child_${v}_${i.toString()}`}
							/>
						);
					})}
				</Tag>
			);
		}
	}
	return null;
};
