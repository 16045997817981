import * as React from 'react';
import { ChakraProvider, extendTheme, Skeleton, Stack } from '@chakra-ui/react';
import { AnimatePresence, motion } from 'framer-motion';
// import { HyperThemeEditor, ThemeEditorProvider } from '@hypertheme-editor/chakra-ui'; //NOTE: Turned off until we have full version due to font overriding.
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { AnimatedHome } from '../presentation/pages/HomePage';
import { AnimatedEarnPointsPage } from '../presentation/pages/EarnPointsPage';
import { AnimatedJoinPage } from '../presentation/pages/JoinPage';
// import { AppContext, IAppConfiguration } from '../data/globalstate';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
// import ToastNotification from '../presentation/components/notifications/ToastNotification';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { AnimatedRewardsPage } from '../presentation/pages/RewardsPage';
import { AnimatedCartPage } from '../presentation/pages/CartPage';
import { AnimatedContactUsPage } from '../presentation/pages/ContactUsPage';
import { AnimatedHowItWorksPage } from '../presentation/pages/HowItWorks';
// import { ITierDto, IUser } from '../domain/models';
import { AnimatedAuthPage } from '../presentation/pages/AuthPage';
import AuthHandler from './AuthHandler';
import { AnimatedProductDetailPage } from '../presentation/pages/ProductDetailPage';
import { AnimatedOrderSummaryPage } from '../presentation/pages/OrderSummaryPage';
import { AnimatedUserProfilePage } from '../presentation/pages/UserProfilePage';
import { AnimatedForgotPasswordPage } from '../presentation/pages/ForgotPasswordPage';
import { AppInitHandler } from './AppInitHandler';
import { AnimatedOrderConfirmationPage } from '../presentation/pages/OrderConfirmationPage';
import { useSharedAppConfiguration } from '@ebbo/pulse-uikit';

const AnimatedSkeleton = motion.create(
	React.forwardRef<HTMLDivElement, unknown>((props, ref) => (
		<Stack ref={ref}>
			<Skeleton height="20px" />
			<Skeleton height="20px" />
			<Skeleton height="20px" />
		</Stack>
	)),
);

const router = createBrowserRouter([
	{
		path: '/',
		element: (
			<AnimatedHome
				initial={{ opacity: 0 }}
				animate={{ opacity: 1 }}
				exit={{
					opacity: 0,
				}}
				transition={{ duration: 1 }}
			/>
		),
		loader: () => <AnimatedSkeleton />,
	},
	{
		path: '/earn-points',
		element: (
			<AnimatedEarnPointsPage
				initial={{ opacity: 0 }}
				animate={{ opacity: 1 }}
				exit={{
					opacity: 0,
				}}
				transition={{ duration: 1 }}
			/>
		),
		loader: () => <AnimatedSkeleton />,
	},
	{
		path: '/contact-us',
		element: (
			<AnimatedContactUsPage
				initial={{ opacity: 0 }}
				animate={{ opacity: 1 }}
				exit={{
					opacity: 0,
				}}
				transition={{ duration: 1 }}
			/>
		),
		loader: () => <AnimatedSkeleton />,
	},
	{
		path: '/join',
		element: (
			<AnimatedJoinPage
				initial={{ opacity: 0 }}
				animate={{ opacity: 1 }}
				exit={{
					opacity: 0,
				}}
				transition={{ duration: 1 }}
			/>
		),
		loader: () => <AnimatedSkeleton />,
	},
	{
		path: '/rewards',
		element: (
			<AnimatedRewardsPage
				initial={{ opacity: 0 }}
				animate={{ opacity: 1 }}
				exit={{
					opacity: 0,
				}}
				transition={{ duration: 1 }}
			/>
		),
		loader: () => <AnimatedSkeleton />,
	},
	{
		path: '/cart',
		element: (
			<AnimatedCartPage
				initial={{ opacity: 0 }}
				animate={{ opacity: 1 }}
				exit={{
					opacity: 0,
				}}
				transition={{ duration: 1 }}
			/>
		),
		loader: () => <AnimatedSkeleton />,
	},
	{
		path: '/how-it-works',
		element: (
			<AnimatedHowItWorksPage
				initial={{ opacity: 0 }}
				animate={{ opacity: 1 }}
				exit={{
					opacity: 0,
				}}
				transition={{ duration: 1 }}
			/>
		),
		loader: () => <AnimatedSkeleton />,
	},
	{
		path: '/login',
		element: (
			<AnimatedAuthPage
				initial={{ opacity: 0 }}
				animate={{ opacity: 1 }}
				exit={{
					opacity: 0,
				}}
				transition={{ duration: 1 }}
			/>
		),
		loader: () => <AnimatedSkeleton />,
	},
	{
		path: '/profile',
		element: (
			<AnimatedUserProfilePage
				initial={{ opacity: 0 }}
				animate={{ opacity: 1 }}
				exit={{
					opacity: 0,
				}}
				transition={{ duration: 1 }}
			/>
		),
		loader: () => <AnimatedSkeleton />,
	},
	{
		path: '/product/:productId',
		element: (
			<AnimatedProductDetailPage
				initial={{ opacity: 0 }}
				animate={{ opacity: 1 }}
				exit={{
					opacity: 0,
				}}
				transition={{ duration: 1 }}
			/>
		),
		loader: () => <AnimatedSkeleton />,
	},
	{
		path: '/checkout',
		element: (
			<AnimatedOrderSummaryPage
				initial={{ opacity: 0 }}
				animate={{ opacity: 1 }}
				exit={{
					opacity: 0,
				}}
				transition={{ duration: 1 }}
			/>
		),
		loader: () => <AnimatedSkeleton />,
	},
	{
		path: '/forgot-password',
		element: (
			<AnimatedForgotPasswordPage
				initial={{ opacity: 0 }}
				animate={{ opacity: 1 }}
				exit={{
					opacity: 0,
				}}
				transition={{ duration: 1 }}
			/>
		),
		loader: () => <AnimatedSkeleton />,
	},
	{
		path: '/order-confirmation', //TODO: Pass Order Id
		element: (
			<AnimatedOrderConfirmationPage
				initial={{ opacity: 0 }}
				animate={{ opacity: 1 }}
				exit={{
					opacity: 0,
				}}
				transition={{ duration: 1 }}
			/>
		),
		loader: () => <AnimatedSkeleton />,
	},
]);

const queryClient = new QueryClient();

const App: React.FC = () => {
	// const [appConfiguration, setAppConfiguration] = React.useState<IAppConfiguration>();
	const [loading, setLoading] = React.useState<boolean>(true);
	// const [showCart, setShowCart] = React.useState<boolean>(false);
	// const [showAuth, setShowAuth] = React.useState<boolean>(false);
	// const [toast, setToast] = React.useState<UseToastOptions>({});
	// const [user, setUser] = React.useState<IUser | undefined>(undefined);
	// const [tiers, setTiers] = React.useState<ITierDto[] | null | undefined>(undefined);
	// const [themeExtended, setThemeExtended] = React.useState<Record<string, unknown>>();
	const { appConfiguration } = useSharedAppConfiguration();

	// React.useEffect(() => {
	// 	// console.log(loading);
	// 	initApp(setLoading, setAppConfiguration, setThemeExtended);
	// 	// setToast({ title: 'Test', status: 'loading', position: 'top', id: 'test1', isClosable: false });
	// 	// setTimeout(() => {
	// 	// 	setToast({
	// 	// 		title: 'Test2',
	// 	// 		status: 'success',
	// 	// 		position: 'bottom',
	// 	// 		id: 'test2',
	// 	// 		isClosable: true,
	// 	// 	});
	// 	// }, 1000);
	// }, []);

	return (
		// <AppContext.Provider
		// 	value={{
		// 		// appConfiguration,
		// 		// setAppConfiguration,
		// 		showCart,
		// 		setShowCart,
		// 		showAuth,
		// 		setShowAuth,
		// 		toast,
		// 		setToast,
		// 		user,
		// 		setUser,
		// 		tiers,
		// 		setTiers,
		// 	}}
		// >
		<QueryClientProvider client={queryClient}>
			<AppInitHandler setLoading={setLoading} />
			<AuthHandler />
			<ChakraProvider
				theme={appConfiguration?.theme ? extendTheme(appConfiguration.theme) : undefined}
				toastOptions={{ defaultOptions: { position: 'bottom' } }}
				portalZIndex={10000}
			>
				<AnimatePresence>
					{loading && (
						<AnimatedSkeleton
							initial={{ opacity: 0 }}
							animate={{ opacity: 1 }}
							exit={{
								opacity: 0,
							}}
							transition={{ duration: 1 }}
						/>
					)}
					{!loading && <RouterProvider router={router} />}
				</AnimatePresence>
				{/* <ThemeEditorProvider>
					<HyperThemeEditor pos="fixed" bottom={4} right={2} aria-label="Theme Editor" />
				</ThemeEditorProvider> */}
				{/* <AnimatePresence>
					{loading && (
						<AnimatedSkeleton
							initial={{ opacity: 0 }}
							animate={{ opacity: 1 }}
							exit={{
								opacity: 0,
							}}
							transition={{ duration: 1 }}
						/>
					)}
					{!loading && (
						<AnimatedHome
							initial={{ opacity: 0 }}
							animate={{ opacity: 1 }}
							exit={{
								opacity: 0,
							}}
							transition={{ duration: 1 }}
						/>
					)}
				</AnimatePresence> */}
				{/* <ToastNotification /> */}
			</ChakraProvider>
			<ReactQueryDevtools initialIsOpen={false} />
		</QueryClientProvider>
		// </AppContext.Provider>
	);
};

export default App;
