import { Box } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import React from 'react';
import { ScrollRestoration, useLocation } from 'react-router-dom';
import {
	// FooterThreeColumn,
	// HeroImageBackground,
	// MapTrail,
	// NavigationBar,
	// PageHeaderCenteredOnAccent,
	// PerksThreeColumn,
	// TierBenefits,
	// useSharedAppConfiguration,
	// CartBar,
	PageTitle,
	useSharedAppConfiguration,
	// RandomProductSlider,
} from '@ebbo/pulse-uikit';
import { RenderEngine } from './RenderEngine';

// const content = {
// 	ROOT: {
// 		type: 'div',
// 		isCanvas: false,
// 		props: {
// 			style: {
// 				minHeight: '25px',
// 			},
// 		},
// 		displayName: 'div',
// 		custom: {},
// 		hidden: false,
// 		nodes: ['mJeRTANbz_', 'sQSJJbxUW3'],
// 		linkedNodes: {},
// 	},
// 	mJeRTANbz_: {
// 		type: {
// 			resolvedName: 'NavigationBar',
// 		},
// 		isCanvas: false,
// 		props: {
// 			user: false,
// 		},
// 		displayName: 'Navigation Bar',
// 		custom: {
// 			allowDelete: false,
// 		},
// 		parent: 'ROOT',
// 		hidden: false,
// 		nodes: [],
// 		linkedNodes: {},
// 	},
// 	sQSJJbxUW3: {
// 		type: 'div',
// 		isCanvas: true,
// 		props: {
// 			style: {
// 				minHeight: '25px',
// 			},
// 		},
// 		displayName: 'div',
// 		custom: {},
// 		parent: 'ROOT',
// 		hidden: false,
// 		nodes: ['uCJqDI5X9s'],
// 		linkedNodes: {},
// 	},
// 	uCJqDI5X9s: {
// 		type: {
// 			resolvedName: 'HeroImageBackground',
// 		},
// 		isCanvas: false,
// 		props: {
// 			title: 'Welcome to Peak Rewards',
// 			text: 'Register for free and start earning points you can use on your next adventure.',
// 			backgroundImageSrc:
// 				'https://burst-pulse-spa.ebbo.com/media/images/HeaderImg_withgreen_2x.webp',
// 			joinButtonText: 'Join Now',
// 			existText: 'Already a peak rewards member?',
// 		},
// 		displayName: 'Hero with Image Background',
// 		custom: {
// 			allowDelete: true,
// 		},
// 		parent: 'sQSJJbxUW3',
// 		hidden: false,
// 		nodes: [],
// 		linkedNodes: {},
// 	},
// };

const HomePage = React.forwardRef<HTMLDivElement, unknown>((props, ref) => {
	// const { appConfiguration } = useSharedAppConfiguration();
	const location = useLocation();
	const { pages } = useSharedAppConfiguration();
	// console.log(location);
	// console.log(pages);

	const page = pages?.find((v) => v.path === location.pathname);
	// console.log(page);

	return (
		<Box textAlign="center" fontSize="xl" ref={ref}>
			{/* TODO Prefix Support? */}
			<PageTitle title={page?.name ?? ''} />
			<ScrollRestoration />
			<RenderEngine
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				layout={page?.properties ? (page.properties.content as Record<string, any>) : {}}
				isRoot
			/>
			{/* <CartBar />
			<NavigationBar /> */}
			{/* <HeroImageBackground
				title={appConfiguration?.content?.HomePage.HeroImageBackground.title ?? 'Test Title'}
				text={appConfiguration?.content?.HomePage.HeroImageBackground.text ?? 'Test Text'}
				backgroundImageSrc={
					appConfiguration?.content?.HomePage.HeroImageBackground.backgroundImageSrc ??
					'https://images.unsplash.com/photo-1590650153855-d9e808231d41?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=2250&q=80'
				}
				joinButtonText={
					appConfiguration?.content?.HomePage.HeroImageBackground.joinButtonText ?? 'Test Join'
				}
				existText={
					appConfiguration?.content?.HomePage.HeroImageBackground.existText ?? 'Test Exist'
				}
			/>
			<PageHeaderCenteredOnAccent
				text={appConfiguration?.content?.HomePage.PageHeaderCenteredOnAccent.text ?? 'Test Text'}
				heading={
					appConfiguration?.content?.HomePage.PageHeaderCenteredOnAccent.heading ?? 'Test Heading'
				}
				logo={
					appConfiguration?.content?.HomePage.PageHeaderCenteredOnAccent.logo ??
					'https://burst-staging.ebbo.com/media/wysiwyg/Pinnacle/no_member/Mountain_img_blue.png'
				}
			/> */}
			{/* <PerksThreeColumn /> */}
			{/* <PageHeaderCentered /> */}
			{/* <RandomProductSlider />
			<TierBenefits backgroundImageSrc="/media/images/chart-bg.webp" />
			<MapTrail />
			<FooterThreeColumn /> */}
		</Box>
	);
});

export const AnimatedHome = motion.create(
	React.forwardRef<HTMLDivElement, unknown>((props, ref) => <HomePage ref={ref} />),
);

export default HomePage;
