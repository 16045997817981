import { useEffect, useState } from 'react';
import { useSharedAppConfiguration, useSharedAppStates, useApiManager } from '@ebbo/pulse-uikit';

interface IAppInitHandler {
	setLoading: React.Dispatch<boolean>;
}

export const AppInitHandler: React.FC<IAppInitHandler> = ({ setLoading }) => {
	const { setAppConfiguration, setApiUrl, apiUrl, setPages, appConfiguration } =
		useSharedAppConfiguration();
	const { setTiers } = useSharedAppStates();
	const { useGetTiers, useGetSiteByDomain } = useApiManager();
	const {
		isLoading: tiersLoading,
		data: tiersData,
		refetch: refetchTiers,
	} = useGetTiers({ enabled: false });
	const [siteHostname, setSiteHostname] = useState<string>(window.location.hostname);
	// const [siteId, setSiteId] = useState<number>(0);
	const {
		isLoading: siteLoading,
		data: siteData,
		refetch: refetchSite,
	} = useGetSiteByDomain({ domain: siteHostname, enabled: false });
	// const {
	// 	isLoading: pagesLoading,
	// 	data: pagesData,
	// 	refetch: refetchPages,
	// } = useGetSitePagesForSite({ siteId: siteId, enabled: false });

	useEffect(() => {
		if (apiUrl) {
			void refetchSite();
		}
		// eslint-disable-next-line
	}, [apiUrl]);

	// Merge Multiple loading states
	useEffect(() => {
		if (tiersLoading || siteLoading) {
			setLoading(true);
		}
		if (!tiersLoading && !siteLoading) {
			setLoading(false);
		}
		// eslint-disable-next-line
	}, [tiersLoading, siteLoading]);

	//On Tier Data
	useEffect(() => {
		// console.log(tiersData);
		if (tiersData && tiersData.tiers !== null) {
			setTiers(tiersData.tiers);
		} else {
			setTiers(undefined);
		}
		// eslint-disable-next-line
	}, [tiersData]);

	//On Site Data
	useEffect(() => {
		// console.log(siteData);
		if (siteData?.properties) {
			// setSiteId(siteData.id);
			setAppConfiguration(siteData.properties);
			setPages(siteData.sitePages ?? undefined);
		}
		// eslint-disable-next-line
	}, [siteData]);

	useEffect(() => {
		// console.log(appConfiguration);
		if (appConfiguration) {
			void refetchTiers();
		}
		// eslint-disable-next-line
	}, [appConfiguration]);

	useEffect(() => {
		// console.log(window.location);
		setSiteHostname(window.location.hostname);

		if (process.env.REACT_APP_API_URL) {
			setApiUrl(process.env.REACT_APP_API_URL);
		}

		// eslint-disable-next-line
	}, []);

	return null;
};
